import React from 'react';
import './App.css';

import Screens from './ui/Screens.js';

function App() {
  return (
    <div className="main">
      <Screens />
    </div>
  );
}

export default App;

import React from 'react';
import logo from '../../logo.png'

import styles from './Landingpage.module.scss';

function Landingpage() {
  return (
    <div className={styles.main}>
      <img className={styles.logo} src={logo} alt="logo" />
    </div>
  );
}

export default Landingpage;

import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import LandingPage from './landingpage';


function Screens() {
  return (
    <div className="main">
    <BrowserRouter>
      <div>
        <Route exact path="/" component={LandingPage} />
      </div>
    </BrowserRouter>
    </div>
  );
}

export default Screens;
